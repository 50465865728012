import React from "react";
import { SvgIcon } from "@material-ui/core";

let VideoIcon = (props) => (
	<SvgIcon {...props}>
    <g data-name="Group 8691">
        <g data-name="Ellipse 10099" style="stroke:#fff;stroke-width:3px;fill:none">
            <circle cx="16" cy="16" r="16" style="stroke:none"/>
            <circle cx="16" cy="16" r="14.5" style="fill:none"/>
        </g>
        <path data-name="Polygon 12" d="m6 0 6 10H0z" transform="rotate(90 6 16)" style="fill:#fff"/>
    </g>
	</SvgIcon>
);

export default VideoIcon;
