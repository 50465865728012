import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Link } from "gatsby";
import CheckIcon from "@material-ui/icons/Check";
import React, { Fragment } from "react";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import useWidth from "../hooks/useWidth";
import journeyOfPepperclude from "../images/v2/about/Journey-of-pepperclude.svg";
import ourStorybg from "../images/v2/about/our-storybg.png";
import ourMissionbg from "../images/v2/about/our-missionbg.png";
import historyCardDotbg from "../images/v2/about/history-card-dotbg.png";
import VideoIcon from "../images/v2/about/VideoIcon";
import { StaticImage } from "gatsby-plugin-image";
import India from "../images/v2/careers/India.svg";
import Singapore from "../images/v2/careers/Singapore.svg";
import Malaysis from "../images/v2/careers/Malaysis.svg";
import Phillpinaas from "../images/v2/careers/Phillpinaas.svg";
import Mynamar from "../images/v2/careers/Mynamar.svg";
import effortlessSelling from "../images/v2/about/effortless-selling.png";
import WhiteButton from "../components/v2/WhightButton";
import SEO from "../components/SEO";
import IMDA_SME_Large from "../images/v2/psg/IMDA_SME_Large.webp";

const useStyles = makeStyles((theme) => ({
  SmeStyling: {
    width: "74%",
    margin: "20px auto 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10px auto 0",
    },
  },
  smeImage: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  smeFooterText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "center",
    },
  },
  aboutRoot: {
    background: `url(${journeyOfPepperclude})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "470px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "18px 12px",
    },
  },
  aboutLightblue: {
    background:
      "linear-gradient(0deg, #E7F2FE 0%, #E7F2FE91 10%, #E7F2FE00 100%) 0% 0% no-repeat padding-box",
  },
  aboutbluebg: {
    background: `url(${ourStorybg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  aboutOurPeople: {
    background: `url(${ourMissionbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  historyItemContainer: {
    "&::before": {
      display: "block",
      content: '""',
      position: "absolute",
      left: 0,
      width: "240px",
      height: "240px",
      borderRadius: "50%",
      background: "#FFC12D",
      zIndex: -1,
      transform: "translate(35%, -20%)",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "&::after": {
      display: "block",
      content: '""',
      position: "absolute",
      right: 0,
      width: "240px",
      height: "240px",
      background: `url(${historyCardDotbg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: -1,
      transform: "translate(-24%, 54%)",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    listStyleType: "none",
    padding: "0px",
  },
  historycardOne: {
    "&::after": {
      display: "block",
      content: '""',
      position: "absolute",
      border: "2px solid #007f1a",
      background: "#007f1a",
      width: "135px",
      transform: "translate(8px, 0)",
    },
  },
  historycardTwo: {
    "&::after": {
      display: "block",
      content: '""',
      position: "absolute",
      border: "2px solid #FCB100",
      background: "#FCB100",
      width: "135px",
      transform: "translate(8px, 0)",
    },
  },
  historycardThree: {
    "&::after": {
      display: "block",
      content: '""',
      position: "absolute",
      border: "2px solid #ff3399",
      background: "#ff3399",
      width: "135px",
      transform: "translate(8px, 0)",
    },
  },
  historyItem: {
    display: "block",
    cursor: "pointer",
    minHeight: "290px",
    minWidth: "358px",
    boxShadow: "2px 2px 6px #00000029",
    borderRadius: theme.shape.borderRadius * 2.5,
    background: "#fff",
    lineHeight: "22px",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
      minWidth: "auto",
      padding: "1rem",
      "& h3": {
        fontSize: "22px",
      },
    },
  },
  historyItemText: {
    fontSize: "16px !important",
  },
  aboutOurOffices: {
    background:
      "linear-gradient(0deg, #E7F2FE 0%, #E7F2FE91 10%, #E7F2FE00 100%)",
  },
  root: {
    maxWidth: 293,
    minHeight: 167,
    padding: "20px 53px 20px 53px",
    borderRadius: "8px",
    boxShadow: "2px 2px 6px #00000029",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      width: 332,
      margin: 0,
      minWidth: "unset",
      padding: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      minWidth: "unset",
      padding: "1rem",
    },
  },
  officeIcon: {
    width: 72,
    height: 72,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(0,50%)",
  },
  requestDemoRoot: {
    background: `url(${effortlessSelling})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  listItemText: {
    "& *": {
      fontSize: 18,
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 30,
    backgroundColor: "#08a742",
    borderRadius: "50%",
    padding: "5px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      marginRight: theme.spacing(1.5),
    },
  },
  VideoIconSvg: {
    width: "32px",
    height: "32px",
    marginLeft: "10px",
  },
  ourPeopleCloud: {
    paddingRight: "64px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "16px",
      textAlign: "center",
    },
  },
  // smeGoDigital: {
  // 	"& a": {
  // 		color: "#198cb5",
  // 	},
  // },
}));

const About = () => {
  const classes = useStyles();
  const width = useWidth();
  const allOffices = [
    {
      title: "Singapore",
      description:
        "#34-04, Tower 1, One Raffles Place,\n" + "Singapore 048616.",
      logo: Singapore,
      alt: "Singapore CRM",
    },
    {
      title: "India",
      description:
        "2nd Floor, Salarpuria Tower 1, 7th Block \n" +
        "Koramangala, Bengaluru, \n" +
        "India - 560095.",
      logo: India,
      alt: "Pepper Cloud CRM - India",
    },
    {
      title: "Malaysia",
      description:
        "6th Floor, No, 3, Jalan SS 7/19, 47301\n" +
        "Petaling Jaya, Selangor, \n" +
        "Malaysia - 47301.",
      logo: Malaysis,
      alt: "Pepper Cloud CRM - Malaysia",
    },
    {
      title: "Philippines",
      description:
        "Centre, No. 47 Kamias Road, \n" +
        "Barangay Pinyahan, Quezon City, \n" +
        "Philippines - 1102",
      logo: Phillpinaas,
      alt: "Pepper Cloud CRM - Phillipines",
    },
    {
      title: "Myanmar",
      description:
        "No.22(A), Kabaraye Pagoda Road, \n" +
        "Bahan Township, Yangon, \n" +
        "Myanmar - 11201.",
      logo: Mynamar,
      alt: "Pepper Cloud CRM - Myanmar",
    },
  ];
  const cloudCRMSoftware =
    `We are passionate proponents of driving sales growth among SMEs. 
		We unify teams and businesses to win over the sales challenges.
		We offer unending end-to-end local support to achieve your sales objectives. 
		We spearhead sales success with a commitment to provide the best of everything. 
		We provide a one-stop CRM solution for all your sales needs.`.split(`
		`);
  return (
    <Fragment>
      <SEO
        canonical="/about"
        description="Sell smarter and faster with Pepper Cloud - Best Sales CRM system for SMEs. Grow with various features like Sales and marketing automation, must-have integration, & more."
        keywords="Best Sales CRM,crm singapore ,crm system singapore,Singapore's Best Sales CRM,Sales CRM software"
        pathname="/about"
        title="Our Story, CRM Mission, and History | Best Sales CRM | Pepper Cloud"
      />
      <Box
        className={classes.aboutRoot}
        // px={2}
        py={{ sm: 4, xs: 4 }[width] || 12}
      >
        <HeaderTypography
          color="common.white"
          component="h2"
          fontSize={40}
          mb={0}
          mt={0}
          textAlign="center"
        >
          The Journey of Pepper Cloud CRM
        </HeaderTypography>
        <Box display="flex" justifyContent="center">
          <ParagraphTypography
            color="#fff"
            fontSize={22}
            maxWidth={720}
            textAlign="center"
          >
            Singapore’s #1 sales CRM software for small and medium enterprises.
          </ParagraphTypography>
        </Box>
      </Box>
      {/* -------------------------second section -----------------------------*/}
      <Box className={classes.aboutLightblue}>
        <Container>
          <Box display="flex" justifyContent="center">
            <Box
              maxWidth={1015}
              py={{ xs: 4, sm: 6, md: 8 }}
              textAlign="center"
            >
              <HeaderTypography component="h3" fontSize={40} mb={3} mt={0}>
                Our story
              </HeaderTypography>
              <ParagraphTypography color="text.paragraph" fontSize={18} mb={2}>
                SMEs are the major contributors to economic growth and have
                colossal growth potential in the coming years with respect to
                job creation, entrepreneurial spirit, and innovation. However,
                SMEs have struggled to take advantage of digitalisation due to
                big and bulky software that are not only expensive but also
                don’t meet their requirements. As a result, SMEs are not able to
                harness their full potential and their sales growth is stalled.
              </ParagraphTypography>
              <ParagraphTypography color="text.paragraph" fontSize={18}>
                We, at Pepper Cloud, believe that all SMEs in Singapore and
                abroad deserve to experience a simple, easy-to-use CRM system to
                manage their sales in this digital age. Hence, with our highly
                functional features, we are offering an all-in-one sales CRM
                software for SMEs that can help to visualise the sales process
                and increase their sales growth.{" "}
              </ParagraphTypography>
              <Box mt={5}>
                <Button
                  className={classes.bookNowButton}
                  color="secondary"
                  component={Link}
                  endIcon={
                    <VideoIcon
                      className={classes.VideoIconSvg}
                      viewBox="0 0 32 32"
                    />
                  }
                  target="_blank"
                  size="large"
                  to="https://youtu.be/wy83dg_yRgc"
                  variant="contained"
                >
                  Watch Video
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
        <Box mb={0} mt={0}>
          <Divider light />
        </Box>
      </Box>
      {/* -----------------------------thired section --------------------------- */}
      <Box>
        <Container>
          <Box display="flex" justifyContent="center">
            <Box
              maxWidth={1015}
              py={{ xs: 4, sm: 6, md: 8 }}
              textAlign="center"
            >
              <HeaderTypography component="h2" fontSize={40} mb={2} mt={0}>
                SME Go Digital - PSG CRM
              </HeaderTypography>
              <ParagraphTypography color="text.paragraph" fontSize={18} mb={2}>
                SMEs are at the heart of Singapore’s economy and to help SMEs
                use digital technologies and build stronger digital
                capabilities,{" "}
                <a href="https://www.imda.gov.sg/">
                  {" "}
                  Infocomm Media Development Authority (IMDA){" "}
                </a>{" "}
                offers PSG grant under the{" "}
                <a href="https://www.imda.gov.sg/programme-listing/smes-go-digital">
                  {" "}
                  SMEs Go digital program
                </a>{" "}
                for Singapore SMEs.
              </ParagraphTypography>
              <ParagraphTypography color="text.paragraph" fontSize={18} mb={2}>
                The grant provides funding up to 50% on the pre-approved IT
                solutions.
              </ParagraphTypography>
              <ParagraphTypography color="text.paragraph" fontSize={18} mb={3}>
                Pepper Cloud CRM is an IMDA-approved PSG CRM Solution. Depending
                upon your team size and business requirement, Pepper Cloud CRM
                has various packages available under the PSG subsidy scheme.
                Please check out our PSG Grant page for more details. For more
                information about how to avail{" "}
                <a href="https://peppercloud.com/psg-grant">PSG grant</a> for
                Pepper Cloud CRM, check out our{" "}
                <a href="https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/">
                  guide to PSG grant
                </a>{" "}
                or talk to our{" "}
                <a href="https://peppercloud.com/contact">
                  customer support team
                </a>
                .
              </ParagraphTypography>
              {/* ---------------------------add logo---------------------- */}
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  mt={6}
                >
                  <Box maxHeight={120} maxWidth={700}>
                    <StaticImage
                      alt="Sales CRM Singapore"
                      className={classes.img}
                      placeholder="blurred"
                      src="../images/v2/about/approved-crm-logo.png"
                    />
                  </Box>
                </Box>
              </Grid> */}
              <Box
                className={classes.smeImage}
                onClick={() =>
                  window.open(
                    "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
                  )
                }
                sx={{ cursor: "pointer" }}
                component={"img"}
                src={IMDA_SME_Large}
                alt={"IMDA Pre-approved Solution Provider"}
                margin={0}
                mb={0}
              />
              <Box className={classes.SmeStyling}>
                <ParagraphTypography
                  color="text.secondary"
                  fontSize={16}
                  sx={{ padding: 0, margin: 0 }}
                  textAlign="center"
                  className={classes.smeFooterText}
                >
                  SMEs are eligible for up to 50% Productivity Solutions Grant
                  (PSG) support for the adoption of Pepper Cloud CRM solution, a
                  Pre-Approved Solution under the IMDA SMEs Go Digital
                  programme.
                </ParagraphTypography>
              </Box>
            </Box>
          </Box>
        </Container>
        <Box mb={0} mt={0}>
          <Divider light />
        </Box>
      </Box>
      {/* --------------------------------fourth section---------------------------- */}
      <Box className={classes.aboutbluebg}>
        <Container>
          <Box display="flex" justifyContent="center">
            <Box
              maxWidth={1015}
              py={{ xs: 4, sm: 6, md: 8, lg: 8 }}
              textAlign="center"
            >
              <HeaderTypography component="h3" fontSize={40} mb={2} mt={0}>
                Our history
              </HeaderTypography>
              <ParagraphTypography color="text.paragraph" fontSize={18} mb={2}>
                Pepper Cloud was founded in 2016 and has evolved into the #1
                Sales CRM Software in Singapore. Headquartered in Singapore,
                Pepper Cloud is amongst a few tech companies with a strong
                regional focus and envisions helping SMEs across the globe,
                starting from Southeast Asia.
              </ParagraphTypography>
              <ParagraphTypography color="text.paragraph" fontSize={18} mb={2}>
                With a strong vision to empower businesses with an efficient
                sales CRM, Pepper Cloud is taking one step at a time, giving you
                more power to control your businesses.
              </ParagraphTypography>
            </Box>
          </Box>
          <Box pb={10} px={{ md: 8 }}>
            <Grid
              aria-labelledby="pc-category"
              className={classes.historyItemContainer}
              component="ul"
              container
              id="pc-category-list"
              justify="center"
              role="menu"
              pt={2}
              spacing={4}
            >
              <Grid
                className={classes.historycardOne}
                component="li"
                item
                md={4}
                role="none"
                sm={6}
                xs={12}
              >
                <Box className={classes.historyItem} pt={2} role="menuitem">
                  <HeaderTypography
                    fontSize={20}
                    fontWeight={600}
                    lineHeight="30px"
                    m={0}
                    component="h1"
                  >
                    Digitise your sales with sales CRM
                  </HeaderTypography>
                  <ParagraphTypography
                    color="text.paragraph"
                    fontWeight="18px"
                    lineHeight="26px"
                    my={2}
                  >
                    We have combined our experience with technology to
                    understand the intricacies of the sales function in
                    organisations across industries. With our commitment to give
                    the best, we help your teams to focus on what helps you grow
                    - Sales.
                  </ParagraphTypography>
                </Box>
              </Grid>
              <Grid
                className={classes.historycardTwo}
                component="li"
                item
                md={4}
                role="none"
                sm={6}
                xs={12}
              >
                <Box className={classes.historyItem} pt={2} role="menuitem">
                  <HeaderTypography
                    fontSize={20}
                    fontWeight={600}
                    lineHeight="30px"
                    m={0}
                    component="h2"
                  >
                    Transform your business with all-in-one CRM
                  </HeaderTypography>
                  <ParagraphTypography
                    color="text.paragraph"
                    fontWeight="18px"
                    lineHeight="26px"
                    my={2}
                  >
                    Pepper Cloud is a one-stop CRM solution for all your sales
                    needs. It is integrated with multiple apps and focuses on
                    re-engineering your business operations, providing you with
                    industry-specific CRM solutions.
                  </ParagraphTypography>
                </Box>
              </Grid>
              <Grid
                className={classes.historycardThree}
                component="li"
                item
                md={4}
                role="none"
                sm={6}
                xs={12}
              >
                <Box className={classes.historyItem} pt={2} role="menuitem">
                  <HeaderTypography
                    fontSize={20}
                    fontWeight={600}
                    lineHeight="30px"
                    m={0}
                    component="h2"
                  >
                    Strengthen your relationship with the best sales CRM
                  </HeaderTypography>
                  <ParagraphTypography
                    color="text.paragraph"
                    fontWeight="18px"
                    lineHeight="26px"
                    my={2}
                  >
                    With a love for building meaningful relationships, Pepper
                    Cloud crafts seamless connections and beautiful
                    conversations between you and your customers to strengthen
                    your relationships. Stop selling, start winning.
                  </ParagraphTypography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      {/* ---------------------------fifth section ------------------------------ */}
      <Box my={{ md: 8, sm: 4, lg: 8, xs: 4 }}>
        <Grid
          alignItems="center"
          container
          direction={{ sm: "row-reverse", xs: "row-reverse" }[width]}
          justify="center"
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <HeaderTypography
              component="h3"
              fontSize={40}
              fontWeight={600}
              m={0}
              textAlign="center"
            >
              Our people - Clouders
            </HeaderTypography>
          </Grid>
          <Grid item md={5} sm={12}>
            <Box py={{ sm: 4, lg: 4, md: 4, xs: 4 }}>
              <StaticImage
                alt="People working with Singapore's best sales CRM software."
                className={classes.img}
                placeholder="blurred"
                src="../images/v2/about/our-people-clouder.png"
              />
            </Box>
          </Grid>
          <Grid item md={1} sm={12} />
          <Grid item md={6} sm={12}>
            <Box display="flex">
              <Box maxWidth={690} p={1}>
                <Box className={classes.ourPeopleCloud}>
                  <ParagraphTypography mb={2} mt={0}>
                    Pepper Cloud CRM is a brainchild of simple minds with
                    significant ideas. The people at Pepper Cloud, the proud
                    Clouders, are a group of intelligent people with a common
                    vision of delivering a simple yet powerful sales CRM
                    solution for SMEs.
                  </ParagraphTypography>
                  <ParagraphTypography mb={5} mt={0}>
                    Pepper Cloud embraces new and unique talents and thus,
                    cultivating a diverse employee culture that encourages unity
                    in diversity. We believe in building technical, professional
                    and leadership qualities within our team to ensure every
                    clouder leads their lives towards success.
                  </ParagraphTypography>
                  <Button
                    className={classes.customButton}
                    color="secondary"
                    component={Link}
                    size="large"
                    to="/careers"
                    variant="contained"
                  >
                    Join our Team
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* ---------------------------sixth section--------------------------------- */}
      <Box
        className={classes.aboutOurPeople}
        py={{ md: 8, sm: 6, lg: 8, xs: 4 }}
      >
        <Box component={Container}>
          <Box display="flex" justifyContent="center">
            <Box pb={3}>
              <Grid alignItems="center" container justify="center" spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box display="flex" justifyContent="center">
                    <HeaderTypography
                      component="h3"
                      fontSize={40}
                      fontWeight={600}
                      mb={2}
                      mt={2}
                      px={{ xs: 2, sm: 2 }}
                      textAlign="center"
                      maxWidth={1025}
                    >
                      Our mission is to be the most preferred Cloud CRM Software
                      in Singapore!
                    </HeaderTypography>
                  </Box>
                </Grid>
                <Grid item md={7} sm={12} xs={12}>
                  <Box mb={0} pr={{ sm: 1, xs: 1 }[width] || 8}>
                    <List component="h4" fontSize="h6.fontSize">
                      {cloudCRMSoftware.map((each) => (
                        <ListItem alignItems="center" key={each}>
                          <ListItemIcon>
                            <CheckIcon className={classes.listItemIcon} />
                          </ListItemIcon>
                          <ListItemText className={classes.listItemText}>
                            {each}
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>
                <Grid item md={5} sm={12} xs={12}>
                  <Box textAlign="center">
                    <StaticImage
                      alt="Singapore best cloud CRM software"
                      className={classes.img}
                      placeholder="blurred"
                      src="../images/v2/about/our-mission-img.jpg"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* -----------------------------seventh section-------------------------------- */}
      <Box
        className={classes.aboutOurOffices}
        m={0}
        px={{ md: 4 }}
        alignItems="center"
        display="flex !important"
        flexDirection="column"
      >
        <Box
          alignItems="center"
          display="flex !important"
          flexDirection="column"
          mb={{ xl: "6", md: "6" }}
          py={{ xl: "3", md: "8" }}
          mx={{ md: "10", lg: "15" }}
          textAlign="center"
          maxWidth={1025}
        >
          <HeaderTypography component="h2" mb={2}>
            Our offices around the world
          </HeaderTypography>
          <Box component={Container} mb={6} mx={{ md: 8 }}>
            <Grid container justifyContent="center" spacing={4}>
              {allOffices.map((each) => (
                <Grid item key={each.title} md={4} sm={6} xs={12}>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    minHeight={180}
                    minWidth={290}
                  >
                    <Avatar
                      alt={each.alt}
                      className={classes.officeIcon}
                      src={each.logo}
                    />
                    <Card className={classes.root}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                      >
                        <Box width="100%">
                          <Box
                            component="h3"
                            fontSize="20px"
                            mt={4}
                            mb={1}
                            fontWeight="600"
                          >
                            {each.title}
                          </Box>
                          <Box
                            color="#2e3f4f"
                            fontSize="14px"
                            fontWeight="600"
                            letterSpacing="normal"
                            lineHeight="1.36"
                          >
                            {each.description}
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        className={classes.requestDemoRoot}
        px={2}
        py={{ sm: 10, xs: 8 }[width] || 12}
      >
        <HeaderTypography
          color="common.white"
          component="h3"
          fontSize={35}
          m={0}
          textAlign="center"
        >
          Are you ready to experience effortless selling?
        </HeaderTypography>
        <ParagraphTypography
          color="common.white"
          component="h3"
          fontSize={24}
          mt={0}
          textAlign="center"
        >
          Drive your sales with the best sales CRM of Singapore.
        </ParagraphTypography>
        <Box mt={6} textAlign="center">
          <WhiteButton
            color="primary"
            component={Link}
            size="large"
            to="/contact"
            variant="contained"
          >
            Get Started
          </WhiteButton>
        </Box>
      </Box>
    </Fragment>
  );
};

export default About;
